import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './App.css';
import Header from './components/organisms/header';
import Home from './components/pages/home';
import BusinessReceipts from './components/pages/business-receipts';
import Footer from './components/organisms/footer';

function App() {
  return (
    <>
      <Header />
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/business-receipts">
            <BusinessReceipts />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;

import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import businessReceipts from '../../../assets/images/business-receipts-logo.png';
import businessReceiptsScreen1 from '../../../assets/images/1.png';
import businessReceiptsScreen2 from '../../../assets/images/2.png';
import businessReceiptsScreen3 from '../../../assets/images/3.png';
import downloadAppLogo from '../../../assets/images/Download_on_the_App_Store_Badge_US-UK_wht_092917.png';
import googlePlayBadge from '../../../assets/images/google-play-badge.png';

export default () => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }, ['/business-receipts']);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Business Receipts | watchinharrison</title>
                <link rel="canonical" href="https://www.watchinharrison.com/business-receipts" />
                <script type="application/ld+json">{`
                {
                    "@context": "http://schema.org",
                    "@type": "SoftwareApplication",
                    "name": "Business Receipts",
                    "downloadUrl": "https://apps.apple.com/gb/app/business-receipts/id1483525834",
                    "@id": "1483525834",
                    "operatingSystem": "iOS",
                    "applicationCategory": "https://schema.org/BusinessApplication",
                    "aggregateRating": {
                      "@type": "AggregateRating",
                      "ratingValue": "5.0",
                      "ratingCount": "1"
                    },
                    "offers": {
                      "@type": "Offer",
                      "price": "0.00",
                      "priceCurrency": "GBP"
                    },
                    "url": "https://watchinharrison.com/business-receipts",
                    "screenshot": "https://watchinharrison.com/${businessReceiptsScreen1}",
                    "thumbnailUrl": "https://watchinharrison.com/${businessReceipts}",
                    "sameAs": [
                        "https://www.facebook.com/watchinharrison",
                        "https://instagram.com/watchinharrison",
                        "https://www.linkedin.com/in/watchinharrison",
                        "https://twitter.com/watchinharrison"
                    ]
                }`}
                </script>
                <script type="application/ld+json">{`
                {
                    "@context": "http://schema.org",
                    "@type": "WebSite",
                    "url": "https://watchinharrison.com"
                }`}
                </script>
            </Helmet>
            <main role="main business-receipts">
                <section class="container">
                    <div class="intro text-center">
                        <h1>Business Receipts</h1>
                        <h4>A simple iOS app for sending e-receipts to customers.</h4>
                    </div>
                </section>
                <section class="description">
                    <div class="">
                        <div class="brief text-center">
                            <h2>Whether you're a remote trader, market stall owner or small on-premise business</h2>
                            <p class="subtitle">Business Receipts can make it easy to generate and email proof of purchase to your customers</p>
                        </div>
                    </div>
                </section>
                <section class="skills">
                    <div class="card-group">
                        <div class="card">
                            <div class="card-body text-center">
                                <h4>Business Receipts</h4>
                            <div>
                                <img src={businessReceiptsScreen1} class="br-img" width="300" height="535"></img>
                            </div>
                            <div>
                                Easily input customer details along with item details.
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body text-center">
                                <h4>Tailored to your Business</h4>
                            <div>
                                <img src={businessReceiptsScreen2} class="br-img" width="300" height="535"></img>
                            </div>
                            <div>
                                Fill in your business details, customise fonts and currency
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body text-center">
                                <h4>PDF Receipt</h4>
                                <div>
                                    <img src={businessReceiptsScreen3} class="br-img" width="300" height="535"></img>
                                </div>
                                <div>
                                    A PDF receipt is embedded in a ready to send email.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="privacy-policy" class="container">
                    <div class="intro pp text-center">
                        <h4>Privacy Policy</h4>
                    </div>
                    <div class="brief text-center">
                        <p class="subtitle">David Harrison ("developer") built the Business Reciepts app as an Ad Supported app. This SERVICE ("app") is provided by at no cost and is intended for use as is). This privacy policy is used to inform you regarding policies with the collection, use, and disclosure of Personal Information if anyone decided to use this Service.</p>
                    </div>
                    <br/>
                    <div class="brief text-center">
                        <h2>Company Details</h2>
                        <br/>
                        <p class="subtitle">The information inputted into the settings is used only to populate the receipt.<br/>Business name, addresses, VAT numbers, phone numbers etc are stored on device and are not transmitted off device, except in the receipt emailed to the customer.<br/>Furthermore all of these details are optional.</p>
                    </div>
                    <br/>
                    <div class="brief text-center">
                        <h2>Customer Details</h2>
                        <br/>
                        <p class="subtitle">The customer information inputted into the customer name and email fields does not leave the device.<br/>They are only used to facilitate the email delivery of the receipt to the customer.</p>
                    </div>
                    <br/>
                    <div class="brief text-center">
                        <h2>Marketing & Ads</h2>
                        <br/>
                        <h3>Google Analytics</h3>
                        <p class="subtitle">Business Receipts uses <a href="https://policies.google.com/technologies/partner-sites" target="_blank">Google Analytics</a> to track events like switching between pages, pressing buttons to add/remove items and send the receipt. Filling in settings will trigger an event, but no inputted values are sent to Google Analytics, this is to understand which fields are most/least used. Furthermore item names, amounts and quantities are also tracked in events to help understand how users are interacting with the application in order to better improve functionality.</p>
                        <strong class="subtitle">If you wish to opt-out of tracking events in GA, you can toggle this in the settings tab.</strong>
                        <br/>
                        <br/>
                        <h3>Google AdMob</h3>
                        <p class="subtitle">Business Receipts uses <a href="https://support.google.com/admob/answer/6128543" target="_blank">Google AdMob</a> to display a single ad for revenue generation.</p>
                        <p class="subtitle">
                            AdMob, is Google's mobile app advertising platform designed specifically for app developers. In order to understand Google's use of Data, consult Google's partner policy.
                        </p>
                        <h4>Personal Data</h4>
                        <ul style={{ 'list-style': 'none', margin: 0, padding: 0}}>
                            <li>Online identifiers, including cookie identifiers</li>
                            <li>IP addresses</li>
                            <li>Device identifiers</li>
                            <li>Client identifiers </li>
                            <li>Unique device identifiers for advertising (Google Advertiser ID or IDFA, for example)</li>
                            <li>Usage Data.</li>
                        </ul>
                        <br/>
                        <h4>Retention</h4>
                        <p class="subtitle">
                            Google anonymizes the log data by removing part of the IP address (after 9 months) and cookie information (after 18 months)
                        </p>
                    </div>
                    <br/>
                    <div class="brief text-center">
                        <h2>Changes to Policy</h2>
                        <br/>
                        <p class="subtitle">This policy may be updated from time to time.</p>
                    </div>
                </section>
                <section class="hire-me">
                    <div class="hire-block">
                    <div class="text-light text-center d-flex flex-wrap align-items-center app-download">
                            <div>
                                <h1>Download it today</h1>
                            </div>
                            <div>
                                <p class="m-0">Business Receipts is available on the App Store and Google Play Store</p>
                            </div>
                            <div>
                                <a class="" href="https://apps.apple.com/gb/app/business-receipts/id1483525834">
                                    <img src={downloadAppLogo} width="165" height="56" />
                                </a>
                                <a class="" href="https://play.google.com/store/apps/details?id=com.watchinharrison.BusinessReceipts">
                                    <img src={googlePlayBadge} width="190" height="56" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
};

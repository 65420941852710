import React from 'react';

export default () => (
    <div class="container">
        <header class="py-3">
            <div class="d-flex flex-nowrap justify-content-between align-items-center">
                <div class="">
                    {/* <a class="text-muted" href="{{ path('posts') }}">Blog</a> */}
                </div>
                <div class="text-center">
                    <a class="blog-header-logo text-dark" href="/">watchinharrison</a>
                </div>
                <div class="d-flex justify-content-end align-items-center">
                    {/* <a class="text-muted" href="{{ path('contact') }}">Contact</a> */}
                </div>
            </div>
        </header>
    </div>
);
